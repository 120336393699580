export const SLIDE_CONFIG = {
  mapFinderComponent: {
    dots: true,
    arrows: true,
    infinite: false,
    appendArrows: ".item__mapa-clinicas-resultado-nav",
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
        breakpoint: 1024,
        settings: "unslick"
      }
    ],
    slidesToShow: 4,
    slidesToScroll: 4,
    rows: 2
  },

  homeMainComponent: {
    dots: false,
    arrows: true,
    infinite: false,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        }
      }, {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        }
      }
    ]
  },
  OpinionesRetinaComponent: {
    dots: false,
    arrows: false,
    infinite: false,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
          adaptiveHeight: true,
        }
      }, {
        breakpoint: 1030,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
          adaptiveHeight: true,
        }
      }
    ]
  },
  rSurgeryMainComponent: {
    dots: false,
    arrows: true,
    infinite: false,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        }
      }, {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        }
      }
    ]
  },

  cansadaResultadosComponent: {
    dots: true,
    arrows: false,
    infinite: false,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        }
      }, {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        }
      }
    ]
  },

  lentesIntraocularesTrifocalesComponent: {
    dots: true,
    arrows: false,
    infinite: false,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        }
      }, {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        }
      }
    ]
  },
  miopiaYPresbiciaComponent: {
    dots: true,
    arrows: false,
    infinite: false,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        }
      }, {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        }
      }
    ]
  },
  operacionPresbiciaComponent: {
    dots: false,
    arrows: false,
    infinite: false,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        }
      }, {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        }
      }
    ]
  },
  operacionPresbiciaPrimeraConsultaAComponent: {
    dslidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    dots: false,
    arrows: true,
    infinite: false,
    variableWidth: true,
    appendArrows: ".slick-controls-mobile>.arrows",
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: false,
          dots: true,
          arrows: false,
        }
      }, {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          variableWidth: false,
          dots: false,
          arrows: true,
        }
      }
    ]
  },
  operacionPresbiciaPrimeraConsultaBComponent: {
    dots: true,
    arrows: false,
    infinite: false,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        }
      }, {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        }
      }
    ]
  },
  precioFinanciacionOperacionPresbiciaComponent: {
    dots: false,
    arrows: true,
    infinite: false,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        }
      }, {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        }
      }
    ]
  },
  tratamientoPresbiciaComponent: {
    dots: true,
    arrows: false,
    infinite: false,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        }
      }, {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        }
      }
    ]
  },
  retinosisPigmentariaComponent: {
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    dots: false,
    arrows: true,
    infinite: false,
    variableWidth: true,
    appendArrows: ".slick-controls-mobile>.arrows",
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    // responsive: [
    // {
    //     breakpoint: 1024,
    //     settings: "unslick"
    // },
    // ]
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: false,
          dots: true,
          arrows: false,
        }
      }, {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          variableWidth: false,
          dots: false,
          arrows: true,
        }
      }
    ]
  },
  operacionCataratasComponent:{
    dots: true,
    arrows: false,
    infinite: false,
    mobileFirst:true,
    responsive: [
    {
        breakpoint: 767,
        settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        }
    }, {
        breakpoint: 1030,
        settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        }
    }
    ]
  },
  operacionCataratasOpinionesComponent:{
    dots: false,
    arrows: true,
    infinite: false,
    mobileFirst:true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
          breakpoint: 767,
          settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
          }
      }, {
          breakpoint: 1030,
          settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
          }
      }
    ]
  },
  operacionCataratasQueEsOpinionesComponent:{
    dots: false,
    arrows: false,
    infinite: false,
    mobileFirst:true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
          breakpoint: 767,
          settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          }
      }, {
          breakpoint: 1030,
          settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
          }
      }
    ]
  },
  operacionCataratasQueEsComponent:{
    dots: false,
    arrows: false,
    infinite: false,
    mobileFirst:true,
    responsive: [
    {
        breakpoint: 767,
        settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        }
    }, {
        breakpoint: 1030,
        settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        }
    }
    ]
  },
  tratamientoCataratasComponent:{
    dots: false,
    arrows: false,
    infinite: false,
    mobileFirst:true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
          breakpoint: 767,
          settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          }
      }, {
          breakpoint: 1030,
          settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
          }
      }
    ]
  },
  operacionCataratasPostoperatorioOpinionesComponent:{
    dots: false,
    arrows: false,
    infinite: false,
    mobileFirst:true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
          breakpoint: 767,
          settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          }
      }, {
          breakpoint: 1030,
          settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
          }
      }
    ]
  },
  RefractivaHipermetropiaA:{
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst:true,
    dots:false,
    arrows: true,
    infinite: false,
    variableWidth: true,
    appendArrows:".slick-controls-mobile>.arrows",
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
    {
      breakpoint: 1024,
      // settings: {
      //   slidesToShow: 3,
      //   slidesToScroll: 1,
      //   dots: false,
      //   arrows: true,
      //   }
      settings:"unslick"
    },
    ]
  },
  RefractivaHipermetropiaB:{
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst:true,
    dots:false,
    arrows: true,
    infinite: false,
    adaptiveHeight:true,
    variableWidth: true,
    appendArrows:".slick-controls-mobile-2>.arrows",
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
    {
      breakpoint: 1024,
      // settings: {
      //   slidesToShow: 3,
      //   slidesToScroll: 1,
      //   dots: false,
      //   arrows: true,
      //   }
      settings:"unslick"
    },
    ]
  },
  Refractivaastigmatismo:{
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst:true,
    dots:false,
    arrows: true,
    infinite: false,
    variableWidth: true,
    appendArrows:".slick-controls-mobile>.arrows",
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
    { breakpoint: 1024,
      // settings: {
      //   slidesToShow: 3,
      //   slidesToScroll: 1,
      //   dots: false,
      //   arrows: true,
      //   }
      settings:"unslick"
    },
    ]
  },
  lasik:{
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst:true,
    dots:false,
    arrows: true,
    infinite: false,
    variableWidth: true,
    appendArrows:".slick-controls-mobile>.arrows",
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
    {
        breakpoint: 1024,
        // settings: {
        //   slidesToShow: 3,
        //   slidesToScroll: 1,
        //   dots: false,
        //   arrows: true,
        //   }
        settings:"unslick"
    },
    ]
  },
  lasek:{
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst:true,
    dots:false,
    arrows: true,
    infinite: false,
    variableWidth: true,
    appendArrows:".slick-controls-mobile>.arrows",
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
     
    {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
          }
    },
    ]
  },
  refractivaPrimeraConsulta:{
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst:true,
    dots:false,
    arrows: true,
    infinite: false,
    variableWidth: true,
    appendArrows:".slick-controls-mobile>.arrows",
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
    {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
          }
    },
    ]
  },
  OpinionesWeb: {
    dots: false,
    arrows: true,
    infinite: false,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        }
      }, {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        }
      }
    ]
  },
  OpinionesOtrosTratamientosComponent: {
    dots: false,
    arrows: true,
    infinite: false,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        }
      }, {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        }
      }
    ]
  },
  CincoRazonesComponent: {
    dots: true,
    arrows: false,
    infinite: false,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        }
      }, {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        }
      }, {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        }
      }
    ]
  },
  OftalmologiaCardsComponent: {
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst:true,
    dots:false,
    arrows: true,
    infinite: false,
    variableWidth: true,
    appendArrows:".slick-controls-mobile>.arrows",
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
    {
        breakpoint: 1024,
        settings: "unslick"
    },
    ]
    // responsive: [
    // {
    //     breakpoint: 767,
    //     settings: {
    //     slidesToShow: 2,
    //     slidesToScroll: 1,
    //     dots: true,
    //     arrows: false,
    //     }
    // }, {
    //     breakpoint: 1030,
    //     settings: {
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     dots: false,
    //     arrows: true,
    //     }
    // }
    // ]

  },

  SliderInteriorDerecha1:{  
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    dots: false,
    arrows: true,
    infinite: true,
    variableWidth: true,
    appendArrows: ".item-text-slider .slick-controls-mobile>.arrows",
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: true,
          appendArrows: ".item-text-slider.uno>.text",
          prevArrow: "<span class='prev'></span>",
          nextArrow: "<span class='next'></span>",
        }
      }]
  },
  SliderInteriorDerecha2:{  
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    dots: false,
    arrows: true,
    infinite: true,
    variableWidth: true,
    appendArrows: ".item-text-slider .slick-controls-mobile>.arrows",
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: true,
          appendArrows: ".item-text-slider.dos>.text",
          prevArrow: "<span class='prev'></span>",
          nextArrow: "<span class='next'></span>",
        }
      }]
  },
  SliderInteriorDerecha3:{  
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    dots: false,
    arrows: true,
    infinite: true,
    variableWidth: true,
    appendArrows: ".item-text-slider .slick-controls-mobile>.arrows",
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: true,
          appendArrows: ".item-text-slider.tres>.text",
          prevArrow: "<span class='prev'></span>",
          nextArrow: "<span class='next'></span>",
        }
      }]
  },
  SliderInteriorIzquierda4:{  
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    dots: false,
    arrows: true,
    infinite: true,
    variableWidth: true,
    appendArrows: ".item-text-slider-reverse .slick-controls-mobile>.arrows",
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          rtl: true,
          arrows: true,
          appendArrows: ".item-text-slider-reverse.cuatro>.text",
          prevArrow: "<span class='prev'></span>",
          nextArrow: "<span class='next'></span>",
        }
      }]
  },  
  SliderInteriorIzquierda5:{  
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    dots: false,
    arrows: true,
    infinite: true,
    variableWidth: true,
    appendArrows: ".item-text-slider-reverse .slick-controls-mobile>.arrows",
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          rtl: true,
          arrows: true,
          appendArrows: ".item-text-slider-reverse.cinco>.text",
          prevArrow: "<span class='prev'></span>",
          nextArrow: "<span class='next'></span>",
        }
      }]
  }, 
  

  clinicaOftMainComponent: {
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    dots: false,
    arrows: true,
    infinite: false,
    variableWidth: true,
    appendArrows: ".slick-controls-mobile>.arrows",
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: true,
          appendArrows: ".section-slider-clinicas>.text",
          prevArrow: "<span class='prev'></span>",
          nextArrow: "<span class='next'></span>",
        }
      }]
  },

  GlaucomaOpinionesComponent:{
    dots:false,
    arrows: false,
    infinite: false,
    mobileFirst:true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
    responsive: [
      {
          breakpoint: 767,
          settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
          }
      }, {
          breakpoint: 1030,
          settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
          }
      }
    ]
  }

}
